<!-- eslint-disable vue/html-self-closing -->

<template>
  <div class="">
    <div class="container-upload">
      <p class="text-center">ลากวางหรือคลิกเพื่ออัปโหลดรูปภาพ</p>
      <small>ขนาดรูปต้องไม่เกิน {{ ruleFileSizeMB }} MB</small>
      <input ref="refInputUploadImage" type="file" accept="image/jpeg, image/png" @input="uploadImages($event)" />
    </div>
    <!-- <pre>{{ ruleImageDimension }}</pre> -->
    <small class="d-block text-danger mt-50 text-right">{{ error }}</small>
    <div class="container-image-preview mt-1">
      <img v-if="file" :src="file.previewUrl" alt="img-preview" class="image-preview" />
      <p v-else class="m-0 text-light">
        โปรดอัปโหลดรูปภาพเพื่อดูตัวอย่าง
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ruleImageDimension: {
      type: Object,
      default: () => null,
    },
    ruleFileSizeMB: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      file: null,
      error: null,
    }
  },
  methods: {
    async uploadImages(ev) {
      const file = ev.target.files[0]
      if (!file) return
      //   console.log('uploadImages', file.naturalWidth)

      if (!this.validateFileSize(file, this.ruleFileSizeMB)) {
        this.error = `ขนาดไฟล์ต้องไม่เกิน ${this.ruleFileSizeMB} MB`
        this.file = null
        this.$refs.refInputUploadImage.value = null
        return
      }

      let newFile = null
      newFile = file

      try {
        const previewUrl = URL.createObjectURL(file)
        const { naturalHeight, naturalWidth } = await this.getDimensionImage(previewUrl)
        // console.log({ naturalHeight, naturalWidth })
        if (naturalHeight === this.ruleImageDimension.height && naturalWidth === this.ruleImageDimension.width) {
          newFile.previewUrl = previewUrl
          this.file = newFile
          this.error = null
        } else {
          this.error = `ขนาดรูปภาพที่อัปโหลดไม่ถูกต้อง รูปภาพต้องมีขนาด ${this.ruleImageDimension.width}(width) x ${this.ruleImageDimension.height}(height)`
          this.file = null
          this.$refs.refInputUploadImage.value = null
        }
      } catch (error) {
        this.error = 'พบข้อผิดพลาดบางอย่าง โปรดติดต่อแอดมิน'
        this.file = null
        this.$refs.refInputUploadImage.value = null
      }
    },
    validateFileSize(file, ruleSize) {
      const fileSize = Number(file.size / (1024 * 1024)).toFixed(2)
      if (fileSize <= ruleSize) return true
      return false
    },
    getDimensionImage(imgUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = e => {
          const { naturalWidth, naturalHeight } = img
          resolve({ naturalHeight, naturalWidth })
        }
        img.onerror = reject

        img.src = imgUrl
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container-upload {
  width: 100%;
  height: 100px;
  background-color: whitesmoke;
  color: rgb(187, 185, 185);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed rgb(187, 185, 185);
  cursor: pointer;
  p {
    margin: 0;
  }
  input {
    width: 100%;
    height: 100%;
    border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.container-image-preview {
  width: 100%;
  // height: 200px;
  min-height: 200px;
  overflow: hidden;
  background-color: #47526f;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .image-preview {
    width: 100%;
  }
}
</style>
